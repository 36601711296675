* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #F9F9F9;
}

#root {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

p {
  padding-top: 3px;
}

.meettastic-home-logo {
  position: absolute; 
  line-height: 26.4px; 
  justify-content: center;
  padding-top: 45px;
  padding-bottom: 30px;
  margin-left: -123px;
  left: 50%;
  align-items: center;

}

.cell {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #777777;
  display: flex;
  align-items: top;
  justify-content: center;
  box-shadow: 0px 10px 47px -10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s;
  font-size: 10px;
  line-height: 10px;
}

.title {
  text-transform: uppercase;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex;
  justify-content: center;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  color: #424242;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.cell:hover {
  box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.3);
}

.details {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #ffffffa0;
  color: white;
  padding: 40px;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.details h1 {
  color: #0096FF;
  font-size: 36px;
  line-height: 36px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  max-width: 750px;
}

.details p {
  color: rgba(0, 0, 0, 0.507);
  font-size: 26px;
  line-height: 26px;
  margin: 0;
  padding-top: 15px;
}

.close {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 26px;
  color: #777777;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 30px 70px 30px 70px;
  height: auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.grid {
  flex: 1;
}

.ant-btn-primary {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: #0096FF !important;
  border-color: #2aadce !important;
}

.ant-btn-secondary {
  background-color: #0096FF !important;
  border-color: #2aadce !important;
  color: white !important;
}


.header {
  background-color: #F9F9F9;
}

#paper {
  color:#000;
  font-size:20px;
}
#margin {
  margin-left:12px;
  margin-bottom:20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; 
}
#text {
  width:90%;
  overflow:hidden;
  background-color:#FFF;
  color:#222;
  font-family:Courier, monospace;
  font-weight:normal;
  font-size:24px;
  resize:none;
  line-height:40px;
  padding-left:100px;
  padding-right:100px;
  padding-top:45px;
  padding-bottom:34px;
  background-image:url(paper.png);
  background-repeat:repeat-y, repeat;
  -webkit-border-radius:10px;
  border-radius:10px;
  -webkit-box-shadow: 0px 2px 5px #000;
  box-shadow: 0px 1px 5px #000;
  border-top:1px solid #FFF;
  border-bottom:1px solid #FFF;
}
#title {
  background-color:transparent;
  border-bottom:3px solid #FFF;
  color:#FFF;
  font-size:20px;
  font-family:Courier, monospace;
  height:28px;
  font-weight:bold;
  width:220px;
}

#wrapper {
  width:700px;
  height:auto;
  margin-left:auto;
  margin-right:auto;
  margin-top:24px;
  margin-bottom:100px;
}

#namer {
 position: relative;
 max-width: 400px;
 margin: 30px auto 0;
}

#namer input {
 border: 0;
 border-bottom: 2px solid #777;
 width: 100%;
 font-size: 30px;
 line-height: 35px;
 height: 70px;
 text-align: center;
 padding: 10px;
 background: transparent;
	color: #000;
}

#namer input.shake {
 animation-name: shaker;
 animation-duration: 200ms;
 animation-timing-function: ease-in-out;
 animation-delay: 0s;
}

#namer input:focus {
 outline: 0;
	color: #000;
}

#namer input::placeholder {
 color: #777;
}

.namer-controls {
 position: relative;
 display: block;
 height: 30px;
 margin: 20px 0;
 text-align: center;
 opacity: 0.3;
 cursor: not-allowed;
}

.namer-controls.active {
 opacity: 1;
 cursor: pointer;
}

.namer-controls div {
 float: left;
 width: 33.33%;
}

.namer-controls div span {
 box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
 padding: 10px 5px;
 width: 95%;
 display: inline-block;
 margin-right: 5%;
 border-radius: 30px;
 font-size: 14px;
 text-transform: uppercase;
 letter-spacing: 0.3px;
}

.namer-controls div span:last-child {
 margin-right: 0;
}

.namer-controls div span.active {
 box-shadow: none;
 background-color: #000;
 color: #fff;
}

#namer-input.serious input {
 letter-spacing: 2px;
 text-transform: uppercase;
 font-family: 'Andada', serif;
 font-weight: 500;
}

#namer-input.modern input {
 font-family: 'Raleway', sans-serif;
 text-transform: lowercase;
 font-weight: 300;
 letter-spacing: 10px;
}

#namer-input.cheeky input {
 font-family: 'Permanent Marker', cursive;
 font-size: 40px;
}

@keyframes shaker {
 0% {
  transform: translate(0px, 0px) rotate(0deg);
  opacity: 0.8;
 }
 10% {
  transform: translate(10px, 7px) rotate(-9deg);
  opacity: 0.6;
 }
 20% {
  transform: translate(13px, -19px) rotate(-3deg);
  opacity: 0.3;
 }
 30% {
  transform: translate(-6px, -6px) rotate(2deg);
  opacity: 0.4;
 }
 40% {
  transform: translate(-9px, -18px) rotate(-5deg);
  opacity: 0.4;
 }
 50% {
  transform: translate(10px, -8px) rotate(5deg);
  opacity: 0.7;
 }
 60% {
  transform: translate(-10px, 14px) rotate(-6deg);
  opacity: 1;
 }
 70% {
  transform: translate(10px, 3px) rotate(6deg);
  opacity: 0.1;
 }
 80% {
  transform: translate(-2px, 20px) rotate(-6deg);
  opacity: 1;
 }
 90% {
  transform: translate(-7px, -19px) rotate(2deg);
  opacity: 0.5;
 }
}

#hastylink {
  position: fixed;
  bottom:5px;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  text-align: center;
	letter-spacing: 1px;
	opacity: 0.5;
}
#hastylink:hover {
	opacity: 1
}